import { render, staticRenderFns } from "./grouping.vue?vue&type=template&id=06a20085&scoped=true&"
import script from "./grouping.vue?vue&type=script&lang=js&"
export * from "./grouping.vue?vue&type=script&lang=js&"
import style0 from "./grouping.vue?vue&type=style&index=0&id=06a20085&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a20085",
  null
  
)

export default component.exports